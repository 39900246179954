
import { AdvertiserInfo } from '@point/utility-classes/src/advertiserInfo';
import Vue from 'vue';
import { GaConnector } from '../../../store/modules/linearadmin/types/google';
import { AccountLinking } from '../../../types/ads';

type GaType = {
  id: string;
  accountId: string;
  propertyId: string;
  viewId: string;
};

export default Vue.extend({
  name: 'linearAdminAccountsTabNew',
  props: ['checkingGA', 'editRights', 'action'],
  components: {
    GoogleAnalyticsNew: () => import('./gaNew/googleAnalyticsNew.vue'),
    FacebookAdsNew: () => import('./facebookAdsNew.vue'),
    GoogleAdManagerNew: () => import('./gamNew/googleAdManager.vue'),
    GoogleAdsNew: () => import('./googleAdsNew/googleAds.vue'),
    SiteImpactNew: () => import('./siteImpactNew/siteImpact.vue'),
    CallRailNew: () => import('./callRailNew/callRail.vue'),
  },
  data: (): {
    loadingAccountLinking: boolean;
    gaData: object | null;
    loadingGA: boolean;
    content: string;
    accountLinking: AccountLinking | null;
    gaConnectors: GaConnector | null;
    selectedGA: GaType | null;
    isCallrailEnabled: boolean;
    isCallrailLoading: boolean;
    isSiteImpactEnabled: boolean;
    isSiteImpactLoading: boolean;
    isFacebookEnabled: boolean;
    isFacebookLoading: boolean;
    isGAMEnabled: boolean;
    isGAMLoading: boolean;
    isGAEnabled: boolean;
    isSEMEnabled: boolean;
    isSEMLoading: boolean;
    isGOOGLEVIDEOEnabled: boolean;
    isGOOGLEVIDEOLoading: boolean;
    isAutoOnboardingEnabled: boolean;
  } => ({
    loadingAccountLinking: true,
    gaData: null,
    loadingGA: true,
    content: 'accounts',
    accountLinking: null,
    gaConnectors: null,
    selectedGA: null,
    isCallrailEnabled: false,
    isCallrailLoading: false,
    isSiteImpactEnabled: false,
    isSiteImpactLoading: false,
    isFacebookEnabled: false,
    isFacebookLoading: false,
    isGAMEnabled: false,
    isGAMLoading: false,
    isGAEnabled: false,
    isSEMEnabled: false,
    isSEMLoading: false,
    isGOOGLEVIDEOEnabled: false,
    isGOOGLEVIDEOLoading: false,
    isAutoOnboardingEnabled: false,
  }),
  async created(): Promise<void> {
    await this.loadAccountLinking();
    await this.loadConnectors();
    if (this.action === 'email') {
      this.changeView('ga');
    }
    if (this.$route.query.view === 'ga') {
      this.changeView('ga');
    }
  },
  mounted(): void {
    if (this.$route.query.view) {
      this.changeView(this.$route.query.view);
    }

    this.isAutoOnboardingEnabled = this.advertiserInfo?.gamAutoOnboarding;
  },
  computed: {
    advertiserInfo(): AdvertiserInfo | null {
      return this.$store.state?.advertiser?.advertiserInfo?.data || null;
    },
    gaConnected(): boolean {
      return (
        this.gaConnectors && this.gaConnectors.Healthy && this.advertiserInfo.GaHealthy && this.advertiserInfo.Connected
      );
    },
    gaNotConnected(): boolean {
      return this.advertiserInfo.GASite === true && !this.advertiserInfo.GaHealthy && this.unhealthyGAStatus;
    },
    unhealthyGAStatus(): string {
      if (!this.gaConnectors?.Healthy) {
        if (this.gaConnectors && this.gaConnectors.ViewStatus.toLowerCase() === 'missing')
          return 'A Google Analytics view must be selected';
        if (!this.gaConnectors) return 'Not connected';
      }
      return '';
    },
    googleAdsEnabled(): boolean {
      return this.accountLinking?.GoogleAds.Enabled;
    },
    googleVideoAdsEnabled(): boolean {
      return this.accountLinking?.GoogleAdsVideo.Enabled;
    },
  },
  methods: {
    async loadAccountLinking(): Promise<void> {
      if (this.editRights.allConnectors && !this.$route.query.view) {
        this.getCallRail();
        this.getSiteImpact();
        this.getFacebookAds();
        this.getGAM();
        this.getGoogleSEM('SEM');
        this.getGoogleVideo('GOOGLEVIDEO');
      }
    },
    async getCallRail(): Promise<void> {
      try {
        this.isCallrailLoading = true;
        const callRailConnection = await this.$store.dispatch(
          'callRail/getAdvertiserCampaigns',
          this.advertiserInfo.id,
        );
        this.isCallrailEnabled = callRailConnection.length;
      } catch (error) {
        console.log('error getting callRail', error);
      } finally {
        this.isCallrailLoading = false;
      }
    },
    async getSiteImpact(): Promise<void> {
      try {
        this.isSiteImpactLoading = true;
        const siteImpactConnection = await this.$store.dispatch('linearAdmin/getSiteImpactConnector', {
          id: this.advertiserInfo.id,
        });
        this.isSiteImpactEnabled = siteImpactConnection.length;
        this.isSiteImpactLoading = false;
      } catch (error) {
        console.log('error getting site impact', error);
        this.isSiteImpactEnabled = false;
        this.isSiteImpactLoading = false;
      }
    },
    async getFacebookAds(): Promise<void> {
      try {
        this.isFacebookLoading = true;
        const facebookConnection = await this.$store.dispatch('linearAdmin/getFacebookConnector', {
          advertiserId: this.advertiserInfo.id,
        });
        this.isFacebookEnabled = facebookConnection?.length;
        this.isFacebookLoading = false;
      } catch (error) {
        console.log('error getting facebook ads', error);
        this.isFacebookEnabled = false;
        this.isFacebookLoading = false;
      }
    },
    async getGAM(): Promise<void> {
      try {
        this.isGAMLoading = true;
        const gamConnection = await this.$store.dispatch('linearAdmin/getGamConnector', {
          id: this.advertiserInfo.id,
        });
        this.isGAMLoading = false;
        this.isGAMEnabled = gamConnection.length;
      } catch (error) {
        console.log('error getting GAM', error);
        this.isGAMEnabled = false;
        this.isGAMLoading = false;
      }
    },
    async getGoogleSEM(): Promise<void> {
      this.isSEMLoading = true;
      const ads = await this.$store.dispatch('googleAds/getConnectedAds', {
        advertiserId: this.advertiserInfo.id,
        type: 'SEM',
      });
      this.isSEMLoading = false;
      this.isSEMEnabled = ads.length;
    },
    async getGoogleVideo(): Promise<void> {
      this.isGOOGLEVIDEOLoading = true;
      const ads = await this.$store.dispatch('googleAds/getConnectedAds', {
        advertiserId: this.advertiserInfo.id,
        type: 'GOOGLEVIDEO',
      });
      this.isGOOGLEVIDEOLoading = false;
      this.isGOOGLEVIDEOEnabled = ads.length;
    },
    loadAdvertiser(): void {
      this.$emit('load-advertiser');
    },
    async loadConnectors(): Promise<void> {
      this.loadingGA = true;
      await this.$store
        .dispatch('linearAdmin/getGaConnector', {
          id: this.$route.query.ppid,
        })
        .then(connectors => {
          this.isGAEnabled = connectors.data.length;
          this.gaConnectors = connectors.data?.[0];
          this.selectGaConnectors();
        });
      this.loadingGA = false;
    },
    toggleAutoOnboarding(): void {
      this.newAdvertiserInfo = {
        ...this.advertiserInfo,
        gamAutoOnboarding: this.isAutoOnboardingEnabled,
      };
      this.$emit('update-advertiser-info', this.newAdvertiserInfo);
    },
    selectGaConnectors(): void {
      const ga: Record<string, string> = {};
      if (!this.gaConnectors?.additional) return;
      if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.account) {
        ga.id = this.$route.query.ppid;
        ga.accountId = this.gaConnectors?.additional?.account?.match(/\d+/)[0];
        ga.propertyId = this.gaConnectors?.primaryId;
      }
      if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.ga_account_id) {
        ga.id = this.$route.query.ppid;
        ga.accountId = this.gaConnectors?.additional?.ga_account_id;
        ga.propertyId = this.gaConnectors?.primaryId;
      }
      if (this.gaConnectors?.additional?.type === 'ga') {
        ga.id = this.$route.query.ppid;
        ga.accountId = this.gaConnectors?.additional?.accountId;
        ga.propertyId = this.gaConnectors?.additional?.id;
        ga.viewId = this.gaConnectors?.primaryId;
      }
      this.selectedGA = ga;
    },
    selectGa(accountId: string, propertyId: string, viewId?: string): void {
      this.selectedGA = { id: this.$route.query.ppid, accountId, propertyId, viewId };
    },
    async addGaConnector(): Promise<void> {
      try {
        if (this.selectedGA) {
          const params = this.selectedGA;
          const resp = await this.$store.dispatch('linearAdmin/addGaConnector', params);

          if (resp.error) {
            // eslint-disable-next-line no-console
            console.log(resp.error);
          }
          if (resp?.addGaConnector?.created) {
            await this.loadConnectors();
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    editAdvertiser(input: string): void {
      this.$emit('edit-advertiser', input);
    },
    changeView(view: string): void {
      this.content = view;
      if (this.$route.query.view !== view) {
        const { ppid } = this.$route.query;
        if (view === 'accounts') {
          this.$router.push({ query: { ppid } });
          // refresh all connectors every time accounts page is opened
          this.loadAccountLinking();
          this.loadConnectors();
        } else {
          this.$router.push({ query: { ppid, view } });
        }
      }
    },
  },
});
